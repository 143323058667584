<template>
  <div>
    <Row v-for="item in $operators.agencies"
         :key="item.id"
         v-on:click.native="showAgency(item)"
         class="agencies-guides__row"
         justify="between">
      <Column class="grid-sm-3 grid-md-4 grid-lg-4 agencies-guides__meta-info">
        <div class="agencies-guides__list-item">
          <h6 class="agencies-guides__list-item-title">{{item.title}}</h6>
          <p class="agencies-guides__list-item-address">{{item.address}}</p>
          <div class="agencies-guides__flex-row">
            <Rating :params="{id: item.id,
                            type: 'agencies',
                            rating: item.rating,
                            currentUserRating: item.currentUserRating}"/>
          </div>
        </div>
      </Column>
      <Column class="grid-sm-1 grid-md-2 grid-lg-2">
        <div class="agencies-guides__list-item-logotype">
          <img class="agencies-guides__list-item-image"
               :src="item.logo || item.images[0]"
               alt/>
        </div>
      </Column>
    </Row>
  </div>
</template>

<script>
export default {
  name: 'AgenciesGuidesAgencies',
  created() {
    this.$store.dispatch('GET_AGENCIES_FROM_SERVER_TO_OPERATORS_WITH_PARAMS', {
      clear: true,
      params: {
        count: 100500,
        city_id: this.$city.id,
        lang: this.$i18n.locale,
      },
    });
  },
  methods: {
    showAgency(item) {
      this.$store.commit('SET_AGENCY_TO_OPERATORS', item);
    },
  },
};
</script>
